<template>
  <div class="mt-4 flex flex-col gap-8">
    <div class="flex w-1/2 flex-col gap-4">
      <div class="flex flex-col gap-2 text-start">
        <span>Lead data enrichment rule name</span>
        <UiInputTextField
          id="name"
          v-model="modelValue.name"
          name="name"
          placeholder="Add rule name"
          :error="useGetFieldErrors(v$, ['name'])"
        />
      </div>
      <div class="flex flex-row items-center gap-4">
        <UiInputCheckbox
          id="new-leads"
          v-model="modelValue.for_new_leads"
          name="new-leads"
          label="Apply to new leads"
          inline-error
          :error="useGetFieldErrors(v$, ['for_new_leads', 'for_old_leads'])"
        />
        <UiInputCheckbox
          id="old-leads"
          v-model="modelValue.for_old_leads"
          name="old-leads"
          label="Apply to existing leads"
        />
      </div>
    </div>
    <div class="flex w-full flex-row gap-12">
      <RotationDataEnrichmentRuleCreateNewRules v-model="modelValue.rules" :v="v$" />
      <RotationDataEnrichmentRuleCreateNewActions v-model="modelValue.actions" :v="v$" />
    </div>
    <div class="mt-2 flex flex-row items-center justify-end gap-4">
      <UiButtonBase id="cancel" type="secondary" @click="$emit('cancel')">Cancel</UiButtonBase>
      <UiButtonBase id="next" @click="next">Next</UiButtonBase>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { LeadEnrichment } from '~/types'

const emits = defineEmits(['cancel', 'next'])

const modelValue = defineModel<LeadEnrichment>({ required: true })

const validateApplies = () => {
  return !(!modelValue.value.for_old_leads && !modelValue.value.for_new_leads)
}

const rules = computed(() => ({
  name: { required: helpers.withMessage('The name is required', required) },
  for_new_leads: {
    validateApplies: helpers.withMessage('At least one option must be selected', validateApplies),
  },
  for_old_leads: {
    validateApplies: helpers.withMessage('At least one option must be selected', validateApplies),
  },
  rules: {
    $each: helpers.forEach({
      rule_name: { required: helpers.withMessage(' ', required) },
      rule_value: { required: helpers.withMessage(' ', required) },
      rule_operator: { required: helpers.withMessage(' ', required) },
      union_operator: { required: helpers.withMessage(' ', required) },
    }),
    validateRules: helpers.withMessage('At least one rule must be added', (value: any) => value.length > 0),
  },
  actions: {
    $each: helpers.forEach({
      field: { required: helpers.withMessage(' ', required) },
      value: { required: helpers.withMessage(' ', required) },
    }),
    validateActions: helpers.withMessage('At least one rule must be added', (value: any) => value.length > 0),
  },
}))

const v$ = useVuelidate(rules, modelValue)

const next = async () => {
  if (!(await v$.value.$validate())) return

  emits('next')
}
</script>

<style scoped></style>
